// src/components/Header.js
import React from 'react';
import { Navbar, Nav, NavDropdown } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import './Header.css'; // Import the CSS file

const Header = () => {
  return (
    <Navbar expand="lg" className="navbar">
      <Navbar.Brand as={NavLink} to="/">
        <img
          src="/logo1.png" // Reference the image in the public directory
          className="logo" // Apply the logo class here
          alt="Company Logo"
        />
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="mr-auto">
          <Nav.Link as={NavLink} to="/" exact activeClassName="active">Hjem</Nav.Link>
          <Nav.Link as={NavLink} to="/about" activeClassName="active">Om oss</Nav.Link>
          <NavDropdown title="Tjenester" id="services-dropdown">
            <NavDropdown.Item as={NavLink} to="/services/Branntetting" activeClassName="active">Branntetting</NavDropdown.Item>
            <NavDropdown.Item as={NavLink} to="/services/Brannmaling" activeClassName="active">Brannmaling</NavDropdown.Item>
            <NavDropdown.Item as={NavLink} to="/services/Brannisolering" activeClassName="active">Brannisolering</NavDropdown.Item>
            <NavDropdown.Item as={NavLink} to="/services/Branndoor" activeClassName="active">Branndører</NavDropdown.Item>
          </NavDropdown>
          <Nav.Link as={NavLink} to="/contact" activeClassName="active">Kontakt</Nav.Link>
          <Nav.Link as={NavLink} to="/references" activeClassName="active">Referanser</Nav.Link>
        </Nav>
        <Nav className="ml-auto">
          <Nav.Link as={NavLink} to="/login" activeClassName="active">Login</Nav.Link>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default Header;
