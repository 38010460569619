// src/components/Footer.js
import React from 'react';
import { FaFacebook, FaTwitter } from 'react-icons/fa';
import './Footer.css'; // Import the CSS file for the footer

const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <footer className="footer">
      <div className="footer-content">
        <div className="footer-section contact-info">
          <h4>Kontaktinfo</h4>
          <p>+47 99 02 95 05</p>
          <p>info@sikkerhetservice.no</p>
        </div>
        <div className="footer-section social-media">
          <a href="https://www.facebook.com" target="_blank" rel="noopener noreferrer">
            <FaFacebook className="icon" />
          </a>
          <a href="https://www.twitter.com" target="_blank" rel="noopener noreferrer">
            <FaTwitter className="icon" />
          </a>
        </div>
        <div className="footer-section menu">
          <p><a href="/about">Om oss</a></p>
          <p><a href="/contact">Kontaktinfo</a></p>
        </div>
      </div>
      <div className="footer-copyright">
        <p>© {currentYear} Sikkerhet Service AS</p>
      </div>
    </footer>
  );
};

export default Footer;
