// src/pages/Contact.js
import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faPhone, faEnvelope, faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import emailjs from 'emailjs-com';
import './Contact.css';

const Contact = () => {
  const [formValues, setFormValues] = useState({
    name: '',
    email: '',
    phone: '',
    description: ''
  });

  const [formErrors, setFormErrors] = useState({
    name: false,
    email: false,
    phone: false,
    description: false
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
    setFormErrors({ ...formErrors, [name]: !value });
  };

  const isFormValid = () => {
    return Object.values(formValues).every(value => value !== '');
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (isFormValid()) {
      emailjs.send('YOUR_SERVICE_ID', 'YOUR_TEMPLATE_ID', formValues, 'YOUR_USER_ID')
        .then((result) => {
          console.log(result.text);
          alert('Message sent successfully!');
          setFormValues({
            name: '',
            email: '',
            phone: '',
            description: ''
          });
        }, (error) => {
          console.log(error.text);
          alert('Failed to send the message, please try again.');
        });
    }
  };

  return (
    <div className="contact-container">
      <h2 className="red-title">Kontakt oss</h2>
      <div className="contact-info">
        <p><FontAwesomeIcon icon={faUser} /> Per Hege Andearsen</p>
        <p><FontAwesomeIcon icon={faPhone} /> +47 99 02 95 05</p>
        <p><FontAwesomeIcon icon={faEnvelope} /> info@sikkerhetservice.no</p>
        <p><FontAwesomeIcon icon={faMapMarkerAlt} /> Børkenes Norway</p>
      </div>
      <h2 className="red-title">Kontakt Form</h2>
      <form className="contact-form" onSubmit={handleSubmit}>
        <div className={`form-group ${formErrors.name ? 'error' : ''}`}>
          <label htmlFor="name">Name <span className="mandatory">* Mandatory</span></label>
          <input
            type="text"
            id="name"
            name="name"
            value={formValues.name}
            onChange={handleInputChange}
            className={formErrors.name ? 'input-error' : ''}
          />
        </div>
        <div className={`form-group ${formErrors.email ? 'error' : ''}`}>
          <label htmlFor="email">Email <span className="mandatory">* Mandatory</span></label>
          <input
            type="email"
            id="email"
            name="email"
            value={formValues.email}
            onChange={handleInputChange}
            className={formErrors.email ? 'input-error' : ''}
          />
        </div>
        <div className={`form-group ${formErrors.phone ? 'error' : ''}`}>
          <label htmlFor="phone">Phone <span className="mandatory">* Mandatory</span></label>
          <input
            type="tel"
            id="phone"
            name="phone"
            value={formValues.phone}
            onChange={handleInputChange}
            className={formErrors.phone ? 'input-error' : ''}
          />
        </div>
        <div className={`form-group ${formErrors.description ? 'error' : ''}`}>
          <label htmlFor="description">Description <span className="mandatory">* Mandatory</span></label>
          <textarea
            id="description"
            name="description"
            value={formValues.description}
            onChange={handleInputChange}
            className={formErrors.description ? 'input-error' : ''}
          />
        </div>
        <button type="submit" disabled={!isFormValid()}>Send</button>
      </form>
    </div>
  );
};

export default Contact;
