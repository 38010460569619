// src/pages/services/Brannisolering.js
import React from 'react';
import './Brannisolering.css'; // Import the CSS file

const Brannisolering = () => {
  return (
    <div className="brannisolering-container">
      <div className="section">
        <div className="text-section">
          <h2 className="section-heading">Brannisolering av stål</h2>
          <p>
            Veiledning til teknisk forskrift 1997 og Forskrift om forebyggende tilltak og brannsyn stål er et velegnet byggemateriale, men det har likevel dårlige branntekniske egenskaper.
          </p>
          <p>
            Stålets kapasitet og styrke reduseres med 50% ved cirka 500ºC denne temperaturen oppnås allerede etter cirka 7 minutter.
          </p>
          <p>
            Vi benytter Protecta A- Plater, Connlit eller glassroc i forskjellig tykkelse i henhold til brannklasse inntil R 220. Den rimeligste metoden å isolere stålkonstruksjoner på, er med bruk av mineralullsplater mekanisk festet(sveiset) til stålet med såkalte Cup-Heads.
          </p>
        </div>
        <div className="image-section">
          <img src="/images/brannisolering2 (1).jpg" alt="Experience" className="section-image" />
          <img src="/images/brannisolering2.jpg" alt="Experience" className="section-image" />
        </div>
      </div>
    </div>
  );
};

export default Brannisolering;
