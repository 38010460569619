// src/pages/services/Tetting.js
import React from 'react';
import './Branntetting.css'; // Import the CSS file

const Tetting = () => {
  return (
    <div className="tetting-container">
      <div className="section">
        <h2 className="section-heading">Hvorfor branntetting?</h2>
        <div className="text-section">
          <p>
            For å hindre og motvirke spredning av brann i et bygg, deles bygget inn i brannseksjoner og brann celler. Brannseksjoner er større deler av bygget som skilles ut fra resten av bygget med seksjoneringsvegger. Brannseksjonens funksjon er å forhindre store branner og samtidig sikre lengre tilgjengelig tid for rømning og redning. Det stilles derfor strenge krav til den bygningsmessige utformingen av seksjoneringsvegger. Avhengig av den spesifikke brannbelastningen i seksjonen, skal seksjoneringsveggen ifølge Veiledning til Teknisk forskrift ha en brannmotstand mellom REIM 90 og REIM 240, normalt enten REIM 90 eller REIM 120.
          </p>
          <p>
            En brann celle er en avgrenset del av bygningen der en brann kan utvikle seg fritt uten å spre seg til andre deler av bygningen i løpet av en fastsatt tid. De skillende konstruksjoner i brann cellen skal ha brannmotstand som angitt i Veiledning til Teknisk forskrift (EI 30 – EI 120), avhengig av bruksområde og brann klasse.
          </p>
        </div>
      </div>
      <div className="section text-image-section">
        <img src="/images/Forside-branntetting.png" alt="Experience" className="section-image" />
        <div className="text-section">
          <h2 className="section-heading">Konsekvenser</h2>
          <p>
            Under en brann vil det som følge av den store varmeutviklingen oppstå et overtrykk i det brannbelastede rommet. Dersom det er utettheter i de omgivende bygningsdeler, vil røyk og flammer kunne presses inn i omkringliggende rom. Røyken fra en brann der mange syntetiske materialer tar fyr, kan inneholde giftige og korrosive gasser som kan føre til skade på helse og materielle verdier.
          </p>
          <p>
            Fremføring av rør, ventilasjonskanaler og elektriske kabler i et byggverk innebærer at en tar hull i vegger og dekker som har brannteknisk funksjon. Det er derfor avgjørende at gjennomføringen utføres slik at bygningsdelens brannmotstand ikke svekkes. I Veiledning til Teknisk forskrift til Plan- og bygningsloven 1997 er dette uttrykt på følgende måte:
          </p>
          <p>
            Installasjoner som føres gjennom brannklassifiserte bygningsdeler, må ha slik utførelse at bygningsdelens brannmotstand ikke svekkes på grunn av gjennomføringen.
          </p>
        </div>
      </div>
      <div className="section">
        <h2 className="section-heading">Hvem har ansvaret for branntetting i bygget?</h2>
        <div className="text-section">
          <p>
            Veiledningen til forskrift om brannforebyggende tiltak og tilsyn (FOBTOT) fastlegger ansvarsforholdet vedrørende de branntekniske hensyn i eksisterende bygningsmasse. I FOBTOT § 2 – 4 Ettersyn og vedlikehold av installasjoner, utstyr, bygningsdeler, fyringsanlegg mv. , heter det:
          </p>
          <p>
            Eier må sørge for at røyk- og branncellebegrensende bygningsdeler er intakte og ikke svekket av hull, samt at konstruksjoner hvor det er krav om brannmotstand virker som forutsatt. Kanaler, rør og lignende som krysser røyk og branncellebegrensende bygningsdeler, og gjennomføringer for disse, skal være utført slik at brannmotstanden ikke reduseres.
          </p>
        </div>
      </div>
      <div className="section">
        <img src="/images/tetting.jpg" alt="Experience" className="section-image-small" />
      </div>
    </div>
  );
};

export default Tetting;
