// src/pages/About.js
import React from 'react';
import './About.css'; // Import the CSS file

const About = () => {
  return (
    <div className="about-container">
      <div className="about-section">
        <div className="about-text-section">
          <h2 className="red-title">Om oss</h2>
          <p>
            Sikkerhet Service AS ble etablert i april 2005 og har hovedkontor i Borkenes. Vi operer primært i Nord Norge, Oslo, Akershus og Buskerud, men er åpen for andre steder i landet.
          </p>
        </div>
        <div className="video-container">
          <iframe
            src="https://www.youtube.com/embed/FZoyhBflvK8"
            title="YouTube video player"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div>
      </div>
      <div className="about-sectionwhite">
        <img src="/images/about.jpg" alt="Experience" className="about-image-small" />
        <div className="about-text-section">
          <h2 className="red-title">Lang erfaring</h2>
          <p>
            Vi har 40 års erfaring innen brannsikring der vi tilbyr et bredt spekter av tjenester i både små og store prosjekter. Selskapet består av en
            av regionens mest erfarne montører av branntettere og isolatører som gjør at vi kan dekke de fleste problemstillinger og utfordringer vi møter innenfor passiv brannsikring.
            Vårt fokus er kunden, arbeidsplassen, dokumentasjon og at arbeidet er utført etter gjeldene forskrifter.
          </p>
        </div>
      </div>
      <div className="about-section">
        <div className="about-text-section">
          <h2 className="red-title">FDV</h2>
          <p>           
          Vi benytter det elektroniske dokumentasjonsystemet, FDV. Dette er et effektivt og ryddig system som rapporterer fra starten av et prosjekt.
           Informasjon som deles er blant annet om når og hvor tettingen er utført, hvem som har utført arbeidet, produktbruk samt brannkrav. Hvis en ønsker
            å kontrollere jobben eller å sjekke en bestemt gjennomføring er sporbarheten svært enkel.
          </p>
        </div>
        <img src="/images/doc1.jpg" alt="Experience" className="about-image-small1" />
      </div>
    </div>
  );
};

export default About;
