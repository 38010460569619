// src/pages/services/Maling.js
import React from 'react';
import './Brannmaling.css'; // Import the CSS file

const Maling = () => {
  return (
    <div className="maling-container">
      <div className="section">
        <h2 className="section-heading">Brannmaling av stål</h2>
        <p>
          Brannmaling er godkjent til brannbeskyttelse av bærende stålkonstruksjoner opp til brannmotstand R 90 (A90).
          Malingen legges normalt i tykkelse fra 0,5 til 4 mm avhengig av krav til brannmotstand (se § 7-23 tabell i REN veiledning til teknisk forskrift), type stålprofil og dens lastutnyttelse.
        </p>
      </div>
      <div className="sectionwhite">
        <div className="text-image-section">
          <img src="/images/brannmaling2.jpg" alt="Plassbesparende" className="section-image" />
          <div className="text-sectionwhite">
            <h2 className="section-heading">Plassbesparende</h2>
            <p>
              Malingen gir en plassbesparende brannisolasjon.
              Malingen må imidlertid ha plass til å ekspandere og passer derfor best for åpne stålkonstruksjoner. Etter brannmalingen påføres et dekkstrøk og overflaten er ferdigbehandlet.
            </p>
            <p>
              Vi utfører brannmaling med sprøyte, rulle eller pensel. Brannmamaling er et produkt som sveller under brann og avgir et skikt som isolerer stålet. Filmtykkelsen avhenger av brannklasse, type stålprofil og lastutnyttelse på den aktuelle stålkonstruksjonen. Påført tykkelse kontrolleres med my-måler. 1000my =1mm.
            </p>
          </div>
        </div>
      </div>
      <div className="section">
        <div className="text-image-section-reverse">
          <div className="text-section">
            <h2 className="section-heading">Helt ny overflatefinish</h2>
            <p>
              Nye produkter og ny påføringsteknikk har ført til at brannmaling i dag fremstår med en helt ny overflatefinish. Fritt fargevalg av topp strøk innenfor NCS’ fargeatlas og RAL-fargesystemet.
            </p>
          </div>
          <img src="/images/31.jpg" alt="Helt ny overflatefinish" className="section-image" />
        </div>
      </div>
    </div>
  );
};

export default Maling;
