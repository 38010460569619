// src/components/ImageSlider.js
import React from 'react';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import './ImageSlider.css'; // Import the CSS file

const ImageSlider = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000, // 5000 milliseconds = 5 seconds
    vertical: true, // Enable vertical sliding
    verticalSwiping: true, // Enable vertical swiping
    fade: false // Disable fade to enable CSS flip animation
  };

  const slidesData = [
    {
      image: '/images/1.jpg'
    },
    {
      image: '/images/2.jpg'
    },
    {
      image: '/images/3.jpg'
    },
    {
      image: '/images/4.jpg'
    },
    {
      image: '/images/5.jpg'
    },
    {
      image: '/images/6.jpg'
    },
    {
      image: '/images/7.jpg'
    },
    {
      image: '/images/8.jpg'
    },
    {
      image: '/images/9.jpg'
    },
    {
      image: '/images/10.jpg'
    },
    {
      image: '/images/11.jpg'
    },
    {
      image: '/images/12.jpg'
    },
    {
      image: '/images/13.jpg'
    },
    {
      image: '/images/14.jpg'
    },
    {
      image: '/images/15.jpg'
    },
    {
      image: '/images/16.jpg'
    },
    {
      image: '/images/17.jpg'
    },
    {
      image: '/images/18.jpg'
    },
    {
      image: '/images/19.jpg'
    },
    {
      image: '/images/20.jpg'
    },
    {
      image: '/images/21.jpg'
    },
    {
      image: '/images/22.jpg'
    },
    {
      image: '/images/23.jpg'
    },
    {
      image: '/images/24.jpg'
    },
    {
      image: '/images/25.jpg'
    },
    {
      image: '/images/26.jpg'
    },
    {
      image: '/images/27.jpg'
    },
    {
      image: '/images/28.jpg'
    },
    {
      image: '/images/29.jpg'
    },
    {
      image: '/images/30.jpg'
    },
    {
      image: '/images/31.jpg'
    },
  ];

  return (
    <div className="slider-container">
      <Slider {...settings}>
        {slidesData.map((slide, index) => (
          <div key={index}>
            <img src={slide.image} alt={`Slide ${index + 1}`} />
            <p className="slider-caption">{slide.caption}</p>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default ImageSlider;
