// src/pages/Home.js
import React, { useState, useEffect } from 'react';
import ImageSlider from '../components/ImageSlider';
import './Home.css'; // Import the CSS file

const newsItems = [
  "News item 1: Important update",
  "News item 2: Latest announcement",
  "News item 3: Upcoming event",
  "News item 4: New product launch",
  "News item 5: Company milestone"
];

const Home = () => {
  const [currentNewsIndex, setCurrentNewsIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentNewsIndex(prevIndex => (prevIndex + 1) % newsItems.length);
    }, 5000); // Change news every 5 seconds

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="home-container">     
      <h2 className="section-two-heading">Velkommen til Sikkerhet Service AS</h2>
      <section className="section-three">
        <div className="slider-container">
          <ImageSlider />
        </div>        
      </section>
      <section className="section-two">
        <h2 className="section-two-heading">Våre tjenester</h2>
        <div className="services-container">          
          <div className="service">
            <img src="/images/tetting.jpg" alt="Tetting" className="service-image" />
            <p className="service-text"><a href="/services/Branntetting">Branntetting</a></p>
          </div>
          <div className="service">
            <img src="/images/brannisolering2 (1).jpg" alt="Isolering" className="service-image" />
            <p className="service-text"><a href="/services/Brannisolering">Brannisolering</a></p>
          </div>
          <div className="service">
            <img src="/images/brannmaling2.jpg" alt="Malling" className="service-image" />
            <p className="service-text"><a href="/services/Brannmaling">Brannmaling</a></p>
          </div>
          <div className="service">
            <img src="/images/branndører.jpg" alt="Door" className="service-image" />
            <p className="service-text"><a href="/services/Branndoor">Branndører</a></p>
          </div>
        </div>
      </section>
      <section className="section-two">
      <div className="news-container">
          <h2 className="news-heading">Nyheter</h2>
          <p className="news-item">{newsItems[currentNewsIndex]}</p>
        </div>
        </section>
    </div>
  );
};

export default Home;
