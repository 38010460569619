// src/App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import Home from './pages/Home';
import About from './pages/About';
import Contact from './pages/Contact';
import Login from './pages/Login';
import Branntetting from './pages/services/Branntetting';
import Brannmaling from './pages/services/Brannmaling';
import Brannisolering from './pages/services/Brannisolering';
import Branndoor from './pages/services/Branndoor';
import './App.css';

const App = () => {
  return (
    <Router>
      <div className="app-container">
        <Header />
        <div className="main-content">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/services/Branntetting" element={<Branntetting />} />
            <Route path="/services/Brannmaling" element={<Brannmaling />} />
            <Route path="/services/Brannisolering" element={<Brannisolering />} />
            <Route path="/services/Branndoor" element={<Branndoor />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/login" element={<Login />} />
          </Routes>
        </div>
        <Footer />
      </div>
    </Router>
  );
};

export default App;
