// src/pages/services/Branndor.js
import React from 'react';
import './Branndoor.css'; // Import the CSS file

const Branndor = () => {
  return (
    <div className="branndor-container">
      <div className="section">
        <div className="image-section">
          <img src="/images/branndor.jpg" alt="Experience" className="section-image" />
          <img src="/images/branndører.jpg" alt="Experience" className="section-image" />
        </div>
        <div className="text-section">
          <h2 className="section-heading">Branndører</h2>
          <p>
            Vi monterer branndører i tre og stål. Brannklassifiserte dører leveres til ulike krav. Det finnes dører med brannmotstand helt opp til 120 med ulike dimensjoner, slagretninger og tilleggsfunksjoner for sikring, lyd og estetikk. Dørene leveres lakkert eller malt i valgfri NCS.
          </p>
        </div>
      </div>
      <div className="line-section">
        <hr className="red-line" />
      </div>
      <div className="section">
        <div className="text-section">
          <h2 className="section-heading">Oppussing av eksisterende dører</h2>
          <p>
            Vi har utført en rekke prosjekter med oppussing av eksisterende dører, slik at de får den brannmotstanden som kreves. Dette er spesielt interessant ved verneverdige dører, eller dører med spesiell estetikk som er vanskelig å produsere men ønskes bevart.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Branndor;
